import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Skills = props => (
    <div className = "skills" >
        <Container fluid className="skills-con">
        <Row className="skills-row">
            <Col className="grid-header">
                <h1 className="skills-head" style={{color: "#1B4079"}}>Skills</h1>
            </Col>
        </Row>
        <Row className="skills-row">
            <Col className="grid-header">
                <p></p>
                <p><i class="bi-tools"></i></p>
                <p>Technologies</p>
            </Col>
            <Col className="grid-header">
                <p></p>
                <p><i class="bi-code-square"></i></p>
                <p>Languages</p>
            </Col>
            <Col className="grid-header">
                <p></p>
                <p><i class="bi-briefcase-fill"></i></p>
                <p>Business</p>
            </Col>
            <Col className="grid-header">
                <p></p>
                <p><i class="bi-clouds-fill"></i></p>
                <p>AWS</p>
            </Col>
        </Row>
        <Row className="skills-row">
            <Col className="skills-col">
                <ul>
                    <li>Docker</li>
                    <li>Kubernetes</li>
                    <li>FastAPI</li>
                    <li>Git</li>
                    <li>Pytest</li>
                    <li>Helm</li>
                    <li>HashiCorp Vault</li>
                    <li>Node.js</li>
                    <li>React</li>
                </ul>
            </Col>
            <Col className="skills-col">
                <ul>
                    <li>Python</li>
                    <li>JavaScript</li>
                    <li>Java</li>
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>SQL</li>
                </ul>
            </Col>
            <Col className="skills-col">                
                <ul>
                    <li>Scaled Agile Framework (SAFe)</li>
                    <li>Lean Six Sigma Yellow Belt</li>
                    <li>Jira</li>
                    <li>New Relic</li>
                    <li>Sumo Logic</li>
                </ul>
            </Col>
            <Col className="skills-col">                
                <ul>
                    <li>S3</li>
                    <li>Elastic Kubernetes Service</li>
                    <li>RDS</li>
                    <li>Lambda</li>
                    <li>CloudWatch</li>
                    <li>Secrets Manager</li>
                    <li>IAM</li>
                    <li>EC2</li>
                </ul>
            </Col>
        </Row>
        </Container>
    </div>
);
export default Skills;