// client/src/pages/mobile.js

import React from "react";
import NavBar, { ElementsWrapper } from "react-scrolling-nav";
import Skills from "./components/skills_mobile";
import Projects from "./components/projects_mobile";
import AboutMe from "./components/about_mobile";
import { Pace, Pause, WindupChildren } from "windups";

class homeMobile extends React.Component {
  getInitialState() {
    return { windowWidth: window.innerWidth };
  }

  handleResize(e) {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const isMounted = true;

    const navbarItems = [
      {
        label: "Home",
        target: "item-1",
      },
      {
        label: "About",
        target: "item-2",
      },
      {
        label: "Skills",
        target: "item-3",
      },
      {
        label: "Projects",
        target: "item-4",
      },
    ];

    this.handleResize = this.handleResize.bind(this);

    return (
      <div>
        <div className="nav">
          <NavBar
            items={navbarItems}
            offset={-70}
            height={70}
            duration={500}
            delay={0}
            backgroundColor={"#000"}
            coverWidth={window.innerWidth}
          ></NavBar>
        </div>
        <div className="container">
          {/* <img src={`${process.env.PUBLIC_URL}/assets/circuit-back.png`} /> */}

          <ElementsWrapper items={navbarItems}>
            <div name="item-1" className="page-odd">
              <WindupChildren>
                <Pace ms={50}>
                  <h1>{`👋 Hi, I'm Max.`}</h1>
                  <Pause ms={500} />
                  <em>{"Welcome to my website, scroll down for more!"}</em>
                </Pace>
              </WindupChildren>
              <div className="main-footer">
                <p>Learn more about me</p>
                <p className="down-arrow">⬇</p>
              </div>
            </div>
            <div name="item-2" className="page-even">
              <AboutMe />
            </div>
            <div name="item-3" className="page-odd">
              <Skills />
            </div>
            <div name="item-4" className="page-even">
              <Projects />
            </div>
          </ElementsWrapper>
        </div>
      </div>
    );
  }
}

export default homeMobile;
