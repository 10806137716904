// client/src/pages/Home.js

import React from "react";
import NavBar, { ElementsWrapper } from "react-scrolling-nav";
import Skills from "./components/skills";
import Projects from "./components/projects";
import AboutMe from "./components/about_me";
import { Pace, Pause, WindupChildren } from "windups";

class Home extends React.Component {
  getInitialState() {
    return { windowWidth: window.innerWidth };
  }

  handleResize(e) {
    this.setState({ windowWidth: window.innerWidth });
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const isMounted = true;

    const navbarItems = [
      {
        label: "Home",
        target: "item-1",
      },
      {
        label: "About",
        target: "item-2",
      },
      {
        label: "Skills",
        target: "item-3",
      },
      {
        label: "Projects",
        target: "item-4",
      },
      // {
      //   label: "Item 5",
      //   target: "item-5",
      // },
      // {
      //   label: "Item 6",
      //   target: "item-6",
      // },
    ];

    this.handleResize = this.handleResize.bind(this);

    return (
      <div>
        <div className="nav">
          <NavBar
            items={navbarItems}
            offset={-70}
            height={70}
            duration={500}
            delay={0}
            backgroundColor={"#000"}
            coverWidth={window.innerWidth}
          ></NavBar>
        </div>
        <div className="container">
          {/* <img src={`${process.env.PUBLIC_URL}/assets/circuit-back.png`} /> */}

          <ElementsWrapper items={navbarItems}>
            <div name="item-1" className="page-odd">
              <WindupChildren>
                <Pace ms={50}>
                  <h1>{`👋 Hi, I'm Max.`}</h1>
                  <Pause ms={500} />
                  <em>{"Welcome to my website, scroll down for more!"}</em>
                </Pace>
              </WindupChildren>
              <div className="main-footer">
                <p>Learn more about me</p>
                <p className="down-arrow">⬇</p>
              </div>
            </div>
            <div name="item-2" className="page-even">
              <div className="page-header">
                <b>About Me</b>
              </div>
              <AboutMe />
            </div>
            <div name="item-3" className="page-odd">
              <Skills />
            </div>
            <div name="item-4" className="page-even">
              <Projects />
            </div>
            {/* <div name="item-5" className="page-odd">
              item 5
            </div>
            <div name="item-6" className="page-even">
              item 6
            </div> */}
          </ElementsWrapper>
        </div>
      </div>
    );
  }
}

export default Home;
