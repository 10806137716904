import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "classnames";

const aboutMobile = (props) => (
  <div className="about-me">
    <Container fluid>
      <Row className="about-header">
        <Col className="grid-header">
          <h1 className="skills-head" style={{ color: "#fff" }}>
            About Me
          </h1>
        </Col>
      </Row>
      <Row className="about-row">
        <Col className={classNames("about-sec")}>
          {/* <p>Max Rosen</p> */}
          <img
            src={`${process.env.PUBLIC_URL}/assets/headshot.jpg`}
            className="about-pic"
          />
        </Col>
      </Row>
      <Row className="about-row">
        <Col className={classNames("about-sec")}>
          <p>
            <i class="bi bi-gear-wide-connected" style={{ color: "#fff" }}></i> Experienced in building and maintaining Python based microservices and REST APIs
          </p>
          <p style={{ color: "#1B4079" }}>
            <i class="bi bi-phone-fill" style={{ color: "#1B4079" }}></i> Published first app to the App Store at age 16, over 200,000
            downloads
          </p>
          <p>
            <i class="bi bi-mortarboard-fill" style={{ color: "#fff" }}></i> Graduated from UMass - Amherst in May of 2021 with a B.S in Computer
            Science
          </p>
          <p style={{ color: "#1B4079" }}>
            <i class="bi bi-geo-fill" style={{ color: "#1B4079" }}></i> Cambridge, MA
          </p>
        </Col>
      </Row>
      <Row className={classNames("about-link", "about-row2")}>
        <a
          target="_blank"
          href="https://github.com/maxrosen"
          className="icon-link"
        >
          <p style={{ color: "#fff" }}>Check out my GitHub!</p>
          <i
            class="bi-github"
            style={{ color: "#6e5494" }}
            id="icon-circle-back"
          ></i>
        </a>
      </Row>
      <Row className={classNames("about-link", "about-row2")}>
        <a
          target="_blank"
          href="https://www.linkedin.com/in/maxfrosen/"
          className="icon-link"
        >
          <p style={{ color: "#1B4079" }}>Connect with me on LinkedIn!</p>
          <i
            class="bi-linkedin"
            style={{ color: "#2867B2" }}
            id="icon-circle-back"
          ></i>
          <p></p>
        </a>
      </Row>
      <Row className={classNames("about-link", "about-row2")}>
        <a
          target="_blank"
          href={`${process.env.PUBLIC_URL}/assets/resume.pdf`}
          className="icon-link"
        >
          <p style={{ color: "#fff" }}>Download my resume!</p>
          <i
            class="bi-file-pdf-fill"
            style={{ color: "#F40F02" }}
            id="icon-rec-back"
          ></i>
        </a>
      </Row>
    </Container>
  </div>
);
export default aboutMobile;
