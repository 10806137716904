import React from "react";
import { Link } from "react-router-dom";

function About() {
  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  return (
    <div className="About">
      <header className="App-header">
        <Link to="/home">
            <button variant="outlined">
                Home
            </button>
        </Link>
      </header>
    </div>
  );
}

export default About;