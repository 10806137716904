// client/src/App.js

import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Link } from "react-router-dom";
import Main from "./Main";
import Mobile from "./Mobile";
import { useMediaQuery } from "react-responsive";

function App() {
  const [data, setData] = React.useState(null);
  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  // const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" });

  React.useEffect(() => {
    fetch("/api")
      .then((res) => res.json())
      .then((data) => setData(data.message));
  }, []);

  return (
    <div className="App">
      {isLandscape && <Main />}
      {isPortrait && <Mobile />}
    </div>
  );
}

export default App;
