import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "classnames";

const Projects = (props) => (
  <div className="proj">
    <Container fluid className="proj-con">
      <Row className="skills-row">
        <Col className="grid-header">
          <h1 className="projs-head" style={{ color: "white" }}>
            Projects
          </h1>
        </Col>
      </Row>
      <Row className="proj-row">
        <Col>
          <a
            target="_blank"
            href="https://github.com/maxrosen/520-EleNa"
            className="project-link"
            rel="noreferrer"
          >
            <p className="project-title">EleNa</p>
          </a>
        </Col>
      </Row>
      <Row className="proj-row">
        <a
          target="_blank"
          href="https://github.com/maxrosen/520-EleNa"
          rel="noreferrer"
          className="blur-link"
        >
          <Col className={classNames("project", "project1")}></Col>
        </a>
      </Row>
      <Row className="proj-row">
        <Col className={classNames("proj-desc")}>
          <p>Python elevation based navigation router</p>
          <p>
            Takes geographic coordinates and will find route to maximize or
            minimize elevation gain, within given parameters
          </p>
        </Col>
      </Row>


      <Row className="proj-row">
        <Col>
          <a
            target="_blank"
            href="https://github.com/maxrosen/TJ-Xpress-Public"
            className="project-link"
            rel="noreferrer"
          >
            <p className="project-title">TJX-Press API</p>
          </a>
        </Col>
      </Row>
      <Row className="proj-row">
        <a
          target="_blank"
          href="https://github.com/maxrosen/TJ-Xpress-Public"
          rel="noreferrer"
        >
          <Col className={classNames("project", "project2")}></Col>
        </a>
      </Row>
      <Row className="proj-row">
        <Col className={classNames("proj-desc")}>
          <p>Team lead for back-end API</p>
          <p>
            Node.js application with Express, uses Sequelize to query Azure
            hosted MySQL database and perform CRUD actions
          </p>
        </Col>
      </Row>


      <Row className="proj-row">
        <Col>
          <a
            className="project-link"
            rel="noreferrer"
          >
            <p className="project-title">Quick Apply</p>
          </a>
        </Col>
      </Row>

      <Row className="proj-row">
        <a
          target="_blank"
          rel="noreferrer"
        >
          <Col className={classNames("project", "project3")}></Col>
        </a>
      </Row>
      <Row className="proj-row">
        <Col className={classNames("proj-desc")}>
          <p>Quick apply microservice</p>
          <p>
            FastAPI Python-based microservice with RDS hosted MySQL DB.
            Interacts with other microservices to send candidate data from
            quick apply flow to clients' ATS systems
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);
export default Projects;
