import { Animator, ScrollContainer, ScrollPage, batch, Fade, FadeIn, Move, MoveIn, MoveOut, Sticky, StickyIn, ZoomIn } from "react-scroll-motion";
import React from "react";
import { Link } from "react-router-dom";



class Presenter extends React.Component {
    render(){
        const ZoomInScrollOut = batch(StickyIn(), FadeIn(), ZoomIn());
        const FadeUp = batch(Fade(), Move(), Sticky());
        return(
            <ScrollContainer>
            <ScrollPage page={0}>
                <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
                <span style={{ fontSize: "30px" }}>Let't me show you scroll animation 😀</span>
                </Animator>
            </ScrollPage>
            <ScrollPage page={1}>
                <Animator animation={ZoomInScrollOut}>
                <span style={{ fontSize: "40px" }}>I'm FadeUpScrollOut ✨</span>
                </Animator>
            </ScrollPage>
            <ScrollPage page={2}>
                <Animator animation={FadeUp}>
                <span style={{ fontSize: "40px" }}>I'm FadeUp ⛅️</span>
                </Animator>
            </ScrollPage>
            </ScrollContainer>
        )
    }
}

export default Presenter;