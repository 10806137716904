import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Home from './pages/home';
import About from './pages/about';
import Presenter from './pages/testing';
import Cube from './pages/components/cube';

// const Main = () => {
class Main extends React.PureComponent{
  render() {
    return (
      <Switch> {/* The Switch decides which component to show based on the current URL.*/}
        <Route exact path='/' component={Home}></Route>
        <Route exact path='/Home' component={Home}></Route>
        <Route exact path='/About' component={About}></Route>
        <Route exact path='/Test' component={Presenter}></Route>
        <Route exact path='/Cube' component={Cube}></Route>

      </Switch>
    );
  }
}

export default Main;